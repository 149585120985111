import React, { useEffect, useRef, useState } from 'react'
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../../components/layout';
import Pagination from '../../components/pagination';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Cursor from '../../components/Cursor';

export default function Projects({ location, data }) {
  const locationRef = useRef({ location: null })

  // if location url change set current page to 0.
  useEffect(() => {
    if (!locationRef.current.location) {
      locationRef.current.location = location
    } else if (locationRef.current.location !== location) {
      setCurrentPage(0)
      locationRef.current.location = location
    }
  }, [location])

  useEffect(() => {
    AOS.init({
      offset: -10,
      delay: 50,
      duration: 800,
      easing: 'ease',
      mirror: true
    });
  }, [])

  const category = location.search.split("category=")[1]
  const projects = data.allPrismicProject.edges
  const PROJECTS_PER_PAGE = 9

  const filteredProjects = projects.filter(project => {
    return (typeof category === "undefined" ||
      project.node.tags.includes(category))
  })

  const [currentPage, setCurrentPage] = useState(0)
  const paginationSliceFrom = currentPage * PROJECTS_PER_PAGE
  const paginationSliceTo = paginationSliceFrom + PROJECTS_PER_PAGE

  return (
    <Layout >
      <Container fluid className="portfolio pt-5">
        <h1 className='my-4'>PORTFOLIO</h1>
        <Row className='project-card-container'>
          {
            filteredProjects
              .slice(paginationSliceFrom, paginationSliceTo)
              .map((project, i) => {
                return (
                  <Col key={i} md={6} xl={4} xxl={3} className="py-3 px-0 px-md-3" data-aos="fade-up">
                    <Link to={`/projects/${project.node.uid}`}>
                      <Cursor light />
                      <div className='project-card'>
                        {project.node.data.project_main_image.gatsbyImageData &&
                          <GatsbyImage image={project.node.data.project_main_image.gatsbyImageData} alt="project main image" />}
                        <div className='project-card-details-wrapper bg-dark text-light'>
                          <div className='project-card-details d-flex flex-column px-4 py-4 justify-content-between'>
                            <h4>{project.node.data.project_category}</h4>
                            <h2>{project.node.data.project_title}</h2>
                            <div className='d-flex justify-content-between'>
                              <h4>{project.node.data.project_year}</h4>
                              <h4>{project.node.data.project_area}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                )
              })
          }
        </Row>
        {filteredProjects.length > PROJECTS_PER_PAGE &&
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={filteredProjects.length} itemsPerPage={PROJECTS_PER_PAGE} />
        }
      </Container>
    </Layout>
  )
}

export const query = graphql`{
  allPrismicProject {
    edges {
      node {
        id
        uid
        tags
        data {
          project_title
          project_year
          project_size
          project_main_image {
            gatsbyImageData
            alt
          }
          project_category
          project_area
        }
      }
    }
  }
}`
